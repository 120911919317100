import { Fragment } from 'react';
import { EyeIcon, FolderOpenIcon, PencilIcon, TrashIcon } from "@heroicons/react/20/solid";
import { deleteData, patchData } from "../../../../services/api/api-tools";
import { WarnModal } from "../../../production/components/pattern-panel/components/WarnModal";
import { useState } from "react";
import { useAlert } from "../../../../contexts/AlertContext";
import { VideoViewerModal } from "./VideoViewerModal";
import { EditPatternModal } from "./EditPatternModal";

export const PatternsTable = ({ patterns, patternsFilteredList }) => {

  const { showAlert } = useAlert();

  const [isWarnModalOpen, setIsWarnModalOpen] = useState(false);
  const [patternIdToDelete, setPatternIdToDelete] = useState(null);
  const [isEditPatternModalOpen, setIsEditPatternModalOpen] = useState(false);
  const [patternToEdit, setPatternToEdit] = useState(null);
  const [isVideoViewerModalOpen, setIsVideoViewerModalOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);

  const handleDeletePatternClick = (pattern) => {
    setPatternIdToDelete(pattern.id);
    setIsWarnModalOpen(true);
  };

  const deletePattern = async () => {
    try {
      await deleteData('patterns', patternIdToDelete);
      patterns.refetch();
      showAlert('success', 'La gamme a été supprimée.');
    } catch (err) {
      console.error('Something bad happened:', err);
      showAlert('warning', 'Une erreur est survenue lors de la suppression de la gamme.');
    } finally {
      setIsWarnModalOpen(false);
      setPatternIdToDelete(null);
    }
  };

  const viewPatternVideo = (pattern) => {
    setVideoUrl(`${process.env.REACT_APP_NODE_EVENTS_PROTOCOL}://` +
      `${process.env.REACT_APP_NODE_EVENTS_URL}/videos/${pattern.id}.mp4`);
    setIsVideoViewerModalOpen(true);
  };

  const viewEditPatternModal = (pattern) => {
    setPatternToEdit(pattern);
    setIsEditPatternModalOpen(true);
  };

  const editPatternName = async (newName) => {
    try {
      const response = await patchData(`patterns/${patternToEdit.id}/info`, { name: newName });
      if (response) {
        patterns.refetch();
        showAlert('success', 'Les informations ont été mises à jour.');
      }
      else showAlert('warning', 'Oops! An error occurred.');
    } catch (err) {
      console.error('Something bad happened:', err);
    }
  };

  return (
    <Fragment>
      <WarnModal
        isOpen={isWarnModalOpen}
        setIsWarnModalOpen={setIsWarnModalOpen}
        onConfirmDelete={deletePattern}
      />

      <VideoViewerModal
        isOpen={isVideoViewerModalOpen}
        setIsWarnModalOpen={setIsVideoViewerModalOpen}
        videoUrl={videoUrl}
      />

      <EditPatternModal
        isOpen={isEditPatternModalOpen}
        setIsEditPatternModalOpen={setIsEditPatternModalOpen}
        patternToEdit={patternToEdit}
        onEdit={editPatternName}
      />

      <table className='w-full mt-4'>
        <thead>
          <tr>
            <th className='px-4 py-2 font-normal text-left w-1/8 bg-perception-gray-800 text-perception-gray-500'>Gamme</th>
            <th className="p-4 text-left w-1/8 bg-perception-gray-800"/>
          </tr>
        </thead>
        <tbody>
          {patternsFilteredList.map((pattern, index) => {
            return (
              <tr key={index}>
                <td className='p-4'>
                  {pattern.name}
                </td>
                <td className='p-4 flex gap-2'>
                  <a href={`/processes/${pattern.id}`}><FolderOpenIcon className='w-5 h-5 ml-2 cursor-pointer'/></a>
                  <span onClick={() => handleDeletePatternClick(pattern)}><TrashIcon className='w-5 h-5 ml-2 cursor-pointer'/></span>
                  <span onClick={() => viewEditPatternModal(pattern)}><PencilIcon className='w-5 h-5 ml-2 cursor-pointer'/></span>
                  <span onClick={() => viewPatternVideo(pattern)}><EyeIcon className='w-5 h-5 ml-2 cursor-pointer'/></span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Fragment>
  );
};

