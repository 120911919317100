import { Panel } from '../../../../components/panels/Panel';
import { PlayIcon, PlusCircleIcon, InboxIcon } from '@heroicons/react/20/solid';
import { PatternsTable } from './PatternsTable';
import { Loader } from "../../../../components/loading/Loader";
import { useOrgData } from "../../../../contexts/OrgDataContext";
import { useEffect } from "react";

export const MainPatternsPanel = ({ patterns }) => {
  const { workshopSelected } = useOrgData();

  const handleCreateNewPattern = () => window.location = '/processes/new';
  const handleStartPattern = () => window.location = '/interface/live';

  // TODO [WIP] This is a dirty way to prevent patterns from bad loading (missing JWT token)
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && !patterns.isLoading && !patterns.data)
      window.location.reload();
  }, [patterns]);

  if (workshopSelected === '' || patterns.isLoading || !patterns.data)
    return <Loader/>

  const workstationIds = workshopSelected.workstations.map((workstation) => workstation.id);
  const patternsFilteredList = patterns.data.filter((pattern) => workstationIds.includes(pattern.workstation.id));

  return (
    <div className='relative flex-1 h-full transition-all duration-500'>
      <div className='flex flex-col w-full h-full overflow-hidden'>
        <Panel category='main' title='LISTE DES GAMMES'>
          <div className='flex flex-col w-full h-full'>
            <div className='flex gap-4'>
              {patternsFilteredList.length > 0 &&
                <button className='flex items-center p-2 text-sm font-bold text-white cursor-pointer bg-perception-blue' onClick={handleStartPattern}>
                  <PlayIcon className='w-5 h-5 mr-2 text-white' /> Démarrer une session live
                </button>
              }
              <button className='flex items-center p-2 text-sm font-bold text-white cursor-pointer bg-perception-blue' onClick={handleCreateNewPattern}>
                <PlusCircleIcon className='w-5 h-5 mr-2 text-white' /> Créer une nouvelle gamme
              </button>
            </div>
            {patternsFilteredList.length > 0 ?
                <PatternsTable patterns={patterns} patternsFilteredList={patternsFilteredList} />
              :
                <div className="flex flex-col justify-center items-center text-perception-gray-800 h-full text-2xl">
                  <InboxIcon className='w-40 h-40'/>
                  <p className="p-4">Aucune gamme enregistrée</p>
                </div>
            }
          </div>
        </Panel>
      </div>
    </div>
  );
};
