import { useState } from "react";
import { deleteData, patchData, postData } from "../../../../../services/api/api-tools";
import { useAlert } from "../../../../../contexts/AlertContext";
import { InputSelect } from "../../../../../components/forms/inputs/InputSelect";
import { usePatternCreator } from "../../../../../contexts/PatternCreatorContext";
import { XMarkIcon } from "@heroicons/react/20/solid";

export const PatternDataPanel = ({ patternData }) => {
  const { showAlert } = useAlert();
  const { pattern, refetchPattern } = usePatternCreator();

  const availableStrass = [{ id: -1, name: null, display_text: "Ajouter une donnée" },
    ...Array.from({ length: 51 }, (_, i) => ({
      id: i,
      name: `strass_pp${i}`,
      display_text: `Strass PP${i}`
    }))
  ];

  const saveNewPatternData = async (selectedItem) => {
    try {
      let path = 'pattern_datas';
      let body = {
        'pattern': `/api/patterns/${pattern.id}`,
        'name': selectedItem.name,
        'display_text': selectedItem.display_text,
        'value': '0'
      };
      const response = await postData(path, body);
      if (response) {
        refetchPattern();
        showAlert('success', 'Les informations ont été mises à jour.');
      } else {
        showAlert('warning', 'Oops! An error occurred.');
      }
    } catch (err) {
      console.error('Something bad happened:', err);
    }
  };

  const onDataSelect = async (selectedItem) => {
    if (!selectedItem) return;
    if (selectedItem?.name === undefined || selectedItem?.name === null) return;
    await saveNewPatternData(selectedItem);
    console.log(selectedItem);
  };

  return (
    <div className='h-full flex flex-col'>
      <div className={`flex flex-col gap-2 ${patternData?.length === 0 ? '' : 'mb-4'}`}>
        {patternData?.filter((data) => data.name !== 'glueing_duration')?.sort((a, b) => a.id - b.id)?.map((data) => (
          <PatternDataRow
            key={data.id}
            data={data}
          />
        ))}
      </div>
      <div className="mb-4">
        <InputSelect
          items={availableStrass}
          onChange={(item) => onDataSelect(item)}
          itemToString={(item) => item?.display_text}
          defaultValue={availableStrass[0]}
          value={availableStrass[0]}
        />
      </div>
      {patternData?.length === 0 &&
        <div className='h-full flex flex-col justify-center items-center text-center'>
          <div className='text-4xl material-icons text-perception-black-700'>info</div>
          <div className='text-perception-gray-700'>Pas de données spécifiques à afficher</div>
        </div>
      }
    </div>
  );
};

const PatternDataRow = ({ data }) => {
  const { showAlert } = useAlert();
  const { refetchPattern } = usePatternCreator();

  const [value, setValue] = useState(data.value);

  const handleValueChange = (e) => {
    const newValue = parseInt(e.target.value);
    setValue(newValue);
  };

  const saveValueChanged = async () => {
    try {
      let path = `pattern_datas/${data.id}`;
      let body = { value: value.toString() };
      const response = await patchData(path, body);
      if (response) {
        showAlert('success', 'Les informations ont été mises à jour.');
      } else {
        showAlert('warning', 'Oops! An error occurred.');
      }
    } catch (err) {
      console.error('Something bad happened:', err);
    }
  };

  const deletePatternData = async (deletedItem) => {
    try {
      await deleteData('pattern_datas', deletedItem.id);
      refetchPattern();
      showAlert('success', 'Les informations ont été mises à jour.');
    } catch (err) {
      console.error('Something bad happened:', err);
    }
  };

  return (
    <div className='flex items-center justify-between gap-2 text-xs text-center'>
      <div className={`uppercase`}>{data.display_text}</div>
      <div className="flex gap-2">
        <input type='text' value={value} onChange={handleValueChange} onBlur={saveValueChanged}
               className='w-12 text-right bg-perception-gray-800 px-1 text-sm focus:outline-none'/>
        {
          data.name !== 'pieces_number' ?
            <XMarkIcon onClick={() => deletePatternData(data)}
                       className='h-5 w-5 bg-perception-gray-800 cursor-pointer hover:bg-perception-blue' aria-hidden='true' />
            :
            <div className="w-5 h-5"/>
        }
      </div>
    </div>
  );
};