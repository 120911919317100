import { Outlet } from 'react-router-dom';
import { PatternCreatorProvider } from "../../contexts/PatternCreatorContext";

export const ProcessLayout = () => {
  return (
    <PatternCreatorProvider>
      <Outlet />
    </PatternCreatorProvider>
  );
};
