import { useEffect, useState } from 'react';
import { Button } from '../../components/buttons/Button';
import { useSocket } from '../../contexts/SocketContext';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { generateRandomString } from '../../helpers/common/generateRandomString';
import { useAlert } from '../../contexts/AlertContext';
import { Loader } from '../../components/loading/Loader';
import { CameraIcon } from '@heroicons/react/24/outline';
import { getInfluxdbData } from "../../services/api/influxdb-tools";

export const CalibrationPage = () => {
  const { deviceToken1, deviceToken2 } = useParams();
  const { showAlert } = useAlert();

  const { sendEvent, subscribeToEvent } = useSocket();

  const [snapshots, setSnapshots] = useState([]);
  const [isUserReceivingSnapshot, setIsUserReceivingSnapshot] = useState(false);

  const [systemIsRunning, setSystemIsRunning] = useState(null);
  const [remainingBattery, setRemainingBattery] = useState(null);

  // System is running or not
  useEffect(() => {
    const getData = async () => {
      const data = await getInfluxdbData(
        'engine',
        'bboxes',
        [], // TODO Add filter and manage multi sites
        ['confidence'],
        '-5m',
        'now()',
        '1ms',
        null,
        'last',
        [],
        null,
        null,
        false,
        'desc',
      );
      setSystemIsRunning(data.length > 0);

      const batteryData = await getInfluxdbData(
        'engine',
        'battery_information',
        [], // TODO Add filter and manage multi sites
        ['battery_percentage'],
        '-60m',
        'now()',
        '1ms',
        null,
        'last',
        [],
        null,
        null,
        false,
        'desc',
      );
      if (batteryData.length > 0)
        setRemainingBattery(Math.floor(batteryData[0].value));
    };

    getData();
  }, []);

  const handleSaveCalibration = async () => {
    if (snapshots.length !== 2) {
      showAlert('warning', 'You need to have two snapshots to save the calibration.');
      return;
    }

    const formData = new FormData();
    formData.append('deviceToken1', deviceToken1);
    formData.append('deviceToken2', deviceToken2);

    const filename1 = `snapshot1_${generateRandomString(5)}.jpg`;
    const filename2 = `snapshot2_${generateRandomString(5)}.jpg`;

    formData.append('file1', base64ToFile(snapshots[0], filename1));
    formData.append('file2', base64ToFile(snapshots[1], filename2));

    try {
      await axios.post(`${process.env.REACT_APP_API_ADDRESS}device_calibration_histories`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      showAlert('success', 'La calibration a été enregistrée.');
    } catch (error) {
      console.error('Error while saving calibration:', error);
      showAlert('error', "Erreur lors de l'enregistrement de la calibration.");
    }
  };

  const handleTakeSnapshot = () => {
    if (isUserReceivingSnapshot) return;
    setIsUserReceivingSnapshot(true);
    sendEvent('take_stream_snapshot', {deviceTokens: [deviceToken1, deviceToken2]});
    setIsUserReceivingSnapshot(true);
  };

  useEffect(() => {
    subscribeToEvent('stream_snapshot', (data) => {
      setIsUserReceivingSnapshot(false);
      setSnapshots(data);

      if (data !== undefined && data !== null && data.length === 0)
        showAlert('error', 'Impossible de récupérer les images des caméras.');
    });
  }, [subscribeToEvent]);

  const base64ToFile = (base64, filename) => {
    let byteString;
    let mimeString = '';

    if (base64.includes(',')) {
      byteString = atob(base64.split(',')[1]);
      mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
    } else {
      byteString = atob(base64);
      mimeString = 'image/jpeg';
    }

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new File([ab], filename, { type: mimeString });
  };

  if (systemIsRunning === null)
    return <Loader/>

  return (
    <div className='flex flex-col justify-center w-full h-full p-2 bg-white'>
      {
        systemIsRunning ?
          <>
            <div className="flex items-center gap-3 ml-2 mb-4 text-sm">
              {
                remainingBattery ?
                  <>
                    <span className='relative flex w-3 h-3'>
                      <span
                        className='absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-perception-success-600'
                      />
                      <span className='relative inline-flex w-3 h-3 rounded-full bg-perception-success-600' />
                    </span>
                    <span>Batterie : {remainingBattery}%</span>
                  </>
                :
                  <>
                    <span className='relative flex w-3 h-3'>
                      <span className='relative inline-flex w-3 h-3 rounded-full bg-perception-success-600' />
                    </span>
                    <span>Système non connecté</span>
                  </>
              }
            </div>

            {/* Buttons */}
            <div className='flex items-center justify-center'>
              <div className='flex items-center gap-2'>
                {snapshots.length ? (
                  <Button type='button' category='tertiary-btn' size='small' onClick={handleSaveCalibration}>
                    Enregistrer
                  </Button>
                ) : (
                  ''
                )}
                <Button type='button' category='secondary-btn' size='small' onClick={handleTakeSnapshot}>
                  {snapshots.length ? 'Réessayer' : 'Prendre une capture'}
                </Button>
              </div>
            </div>

            {/* Loader */}
            {isUserReceivingSnapshot && <Loader />}

            {snapshots.length === 0 ? (
              /* Empty content */
              <div className='flex flex-col justify-center items-center h-full'>
                <CameraIcon className='w-32 h-32 text-gray-400' />
                <span className='text-lg text-gray-400 font-semibold'>Calibration des caméras</span>
              </div>
            ) : (
              /* Snapshots */
              <div className='flex flex-col justify-center items-stretch h-full gap-2 mt-2 camera-container'>
                {snapshots.length
                  ? snapshots.map((snapshot, index) => (
                      <div className='' key={index}>
                        <img
                          src={`data:image/jpeg;base64,${snapshot}`}
                          alt={`Snapshot ${index + 1}`}
                          className='h-auto max-w-full rounded shadow w-full'
                        />
                      </div>
                    ))
                  : null}
              </div>
            )}
          </>
          :
          <div className='flex flex-col justify-center items-center'>
            <p className="text-center mb-4">Le système n'est pas encore actif.<br/>
            Merci de réessayer dans quelques minutes.</p>
            <Button type='button' category='tertiary-btn' size='small' onClick={() => window.location.reload()}>
              Recharger
            </Button>
          </div>
      }
    </div>
  );
};
