import { useEffect, useRef, useState } from "react";
import { formatSecondsToMinutesSeconds } from "../../../../helpers/date/date-helper";
import { usePatternCreator } from "../../../../contexts/PatternCreatorContext";
import { PHASES_COLORS, PHASES_TEXTS } from "../../../../services/live-interface/lca-interface-service";
import {
  isPatternAnnotationMode, PATTERNS_ANNOTATION_MODE_PHASES,
  PATTERNS_ANNOTATION_MODE_STEPS
} from "../../../../services/live-interface/patterns-annotation-service";

export const TimelineVideo = ({ frames, patternEventTypes, patternPhases, onProgress }) => {
  const { progress, updateProgress, duration, setSelectedPatternEventTypeId } = usePatternCreator();

  const timelineRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  const framesNumber = frames.length > 0 ? frames.length : 10;
  const tickInterval = duration / framesNumber;

  const handleTimelineClick = (e) => {
    const rect = timelineRef.current.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const progressPercent = Math.min(Math.max(0, offsetX / rect.width * 100), 100);
    const newTime = Math.min(Math.max(0, (offsetX / rect.width) * duration), duration);
    updateProgress(newTime);

    if (onProgress !== undefined && onProgress !== null)
      onProgress({played: progressPercent, playedSeconds: newTime})
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    handleTimelineClick(e);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      handleTimelineClick(e);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  return (
    <div className='flex flex-col border-t relative border-perception-gray-800 bg-perception-black-800 h-[150px] w-full overflow-hidden'>
      <div className='w-full h-full'>
        <div
          ref={timelineRef} onMouseDown={handleMouseDown}
          className='relative w-full h-full cursor-noner-pointer timeline-container'
          onClick={() => setSelectedPatternEventTypeId(null)}
        >
          <div className='w-full h-2/6'>
            {/* Ticks line */}
            {Array.from({ length: framesNumber + 1 }).map((_, tickIndex) => {
              const tickTime = tickIndex * tickInterval;
              const tickPosition = (tickTime / duration) * 100;
              const halfTickPosition = (tickTime + tickInterval / 2) / duration * 100;

              return (
                <div key={tickIndex} className='inline-block select-none' style={{ width: `${100 / framesNumber}%` }}>
                  {/* TICK */}
                  <div className="absolute border-l border-perception-gray-600"
                       style={{ height: "10px", left: `${tickPosition}%`, top: "5px" }}/>

                  {/* HALF TICK */}
                  {halfTickPosition <= 100 && (
                    <div className="absolute border-l border-perception-gray-800"
                         style={{height: "5px", left: `${halfTickPosition}%`, top: "5px"}}/>
                  )}

                  {/* TIME */}
                  <div className='text-xs text-perception-gray-600'
                       style={{ position: 'absolute', left: `${tickPosition}%`, top: '20px', transform: 'translateX(-50%)' }}
                  >
                    {formatSecondsToMinutesSeconds(tickTime)}
                  </div>
                </div>
              );
            })}

            {/* Pattern event types ticks */}
            {isPatternAnnotationMode(PATTERNS_ANNOTATION_MODE_STEPS) && patternEventTypes.map((tick, index) => {
              const tickPosition = (tick.time / duration) * 100;
              return (
                <div
                  key={`pet-${index}`}
                  className={`border-l h-full border-perception-blue border-2 absolute top-0 z-30`}
                  style={{ left: `${tickPosition}%` }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedPatternEventTypeId(tick.id);
                  }}
                />
              );
            })}

            {/* Pattern phases bars */}
            {isPatternAnnotationMode(PATTERNS_ANNOTATION_MODE_PHASES) && patternPhases.map((bar, index) => {
              if (bar.type <= 0) return null;

              const barPosition = bar.start_date / duration * 100;
              const barWidth = (bar.end_date - bar.start_date) / duration * 100;
              const barColor = PHASES_COLORS[bar.type];
              const barText = PHASES_TEXTS[bar.type];
              return (
                <div
                  key={`pp-${index}`}
                  className={`h-[40%] absolute bottom-0 p-1 z-30 `}
                  style={{ left: `${barPosition}%`, width: `${barWidth}%` }}
                >
                  <div className={`h-full ${barColor} select-none rounded-sm flex items-center p-2 text-sm text-white border-1 border-white border`}>{barText}</div>
                </div>
              );
            })}

          </div>

          {/* Video frames previews */}
          <div className='flex w-full h-4/6' draggable={false}>
            {frames.map((frame) => (
              <div
                className='grow bg-center bg-cover text-xs text-black'
                key={frame}
                style={{ backgroundImage: `url('${frame}')` }}
              />
            ))}
          </div>

          <div
            className='absolute top-0 z-40 h-full bg-perception-gray-500'
            style={{
              width: '2px',
              top: '0px',
              left: `${(progress / duration) * 100}%`,
            }}
          />
        </div>
      </div>
    </div>
  );
};
