import { Modal } from '../../../../components/modals/Modal';
import { VideoPlayer } from "../../../../components/others/VideoPlayer";

export const VideoViewerModal = ({ isOpen, setIsWarnModalOpen, videoUrl }) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsWarnModalOpen} size='large'>
      <div className="w-full overflow-y-auto h-full">
        <VideoPlayer videoUrl={videoUrl} />
      </div>
    </Modal>
  );
};
