import { DATE_RANGES } from '../../../contexts/DashboardContext';

// This function splits an array of charts into two arrays based on a given filter.
export const splitCharts = (charts, filter) => {
  return [
    charts.filter((chart) => chart.context === filter && chart.is_in_summary),
    charts.filter((chart) => chart.context === filter && !chart.is_in_summary),
  ];
};

// Processes chart data to prepare it for rendering.
// It creates an object containing the chart's ID, name, and either a calculated value (for "donut" charts),
// a formatted value (for "value" charts), or raw data points (for other chart types).
export const _getDatasetDisplayData = (chart, chartData, data, selectedDateRange) => {
  const datasetDisplayData = {
    chart: chart,
    chartData: chartData,
    name: chartData.series_name,
    type: chart.type,
    value: null,
    points: null,
    trend: null,
  };

  if (data.length > 0) {
    // Note: currently, only "donut" charts need a post treatment function
    // Next lines may need a refacto to address other metrics
    if (chart.type === 'donut') datasetDisplayData.value = data[0].value.toFixed(0);
    else if (chart.type === 'donut_duration')
      datasetDisplayData.value = calculateUsageRate(data[0].value, selectedDateRange.value);
    else if (chart.type.startsWith('value')) datasetDisplayData.value = data[0].value.toFixed(0);
    // Line, Scatter, etc.
    else datasetDisplayData.points = data;
  }

  return datasetDisplayData;
};

// Calculate the percentage change between the current and the previous value
export const _computeTrendBetweenValues = (value, previousValue) => {
  if (previousValue === null || previousValue === 0) return value === null || value === 0 ? 0 : 0;
  if (value === null) return 0;

  if (!Number.isFinite(value) || !Number.isFinite(previousValue))
    console.error('Computing trend is only possible with real values.');

  return (((value - previousValue) / previousValue) * 100).toFixed(2);
};

/**
 * Compute the usage rate based on the provided logs, targets, and date range
 */
export const calculateUsageRate = (usageDuration, dateRange) => {
  const usageRate = (usageDuration / (dateRange / 1000)) * 100;
  return Number(usageRate.toFixed(1));
};

export const getRandomColor = () => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return `rgb(${r}, ${g}, ${b})`;
};

export const graphColors = [
  'rgb(255, 99, 132)',
  'rgb(75, 192, 192)',
  'rgb(255, 159, 64)',
  'rgb(153, 102, 255)',
  'rgb(255, 153, 153)',
  'rgb(54, 162, 235)',
  'rgb(255, 205, 86)',
  'rgb(153, 255, 153)',
  'rgb(255, 204, 153)',
  'rgb(153, 204, 255)',
  'rgb(255, 255, 153)',
  'rgb(204, 153, 255)',
  'rgb(255, 102, 102)',
  'rgb(102, 255, 255)',
  'rgb(204, 204, 204)',
];

const _rgbToRgba = (rgbString, alpha = 0.3) => {
  const rgbValues = rgbString.match(/\d+/g).map(Number);
  return `rgba(${rgbValues[0]}, ${rgbValues[1]}, ${rgbValues[2]}, ${alpha})`;
};

// Convert logs into a chart scatter dataset for visualization (not used yet)
export const convertLogToActivityChart = (logs, workstations) => {
  const groupedLogs = logs.reduce((acc, log) => {
    const objectId = log.object_id;
    if (!acc[objectId]) {
      acc[objectId] = [];
    }
    acc[objectId].push({
      x: new Date(log.time).getTime(),
      y: Math.floor(Math.random() * 101),
    });
    return acc;
  }, {});

  const targetToWorkstation = {};
  workstations.forEach((workstation) => {
    workstation.targets.forEach((target) => {
      targetToWorkstation[target.id] = workstation.id;
    });
  });

  const workstationColors = {};
  workstations.forEach((workstation) => {
    workstationColors[workstation.id] = getRandomColor();
  });

  const datasets = workstations.map((workstation) => {
    const label = `${workstation.name}`;
    const workstationLogs = workstation.targets.reduce((acc, target) => {
      const objectId = target.id;
      if (groupedLogs[objectId]) {
        acc = acc.concat(groupedLogs[objectId]);
      }
      return acc;
    }, []);

    return {
      label: label,
      data: workstationLogs,
      backgroundColor: workstationColors[workstation.id],
    };
  });

  return { datasets };
};

// Initialize an array of datasets with custom properties
const _initLineDataset = (dataset, index) => {
  const color = dataset.chartData.series_color ? dataset.chartData.series_color : graphColors[index];
  return {
    id: dataset.chartData.id,
    chartId: dataset.chart.id,
    label: dataset.chartData.series_name,
    data: [],
    fill: true,
    borderColor: color,
    backgroundColor: _rgbToRgba(color),
    tension: 0.3,
    borderWidth: 2,
    pointStyle: false,
  };
};

// series data : It contains key-value pairs in the following format: targetId: workstationId.
// series map : It contains key-value pairs in the following format: workstationId: workstation data.
export const getLineDatasetsFromPoints = (dataset, datasetIndex, selectedDateRange) => {
  const lineDataset = _initLineDataset(dataset, datasetIndex);

  if (dataset.points?.length) {
    for (const point of dataset.points) {
      lineDataset.data.push({ x: new Date(point.time), y: point.value ? point.value.toFixed(2) : 0 });
      lineDataset.order = 1;
    }
  } else {
    // If there are no points, add a single data point with a null value to show the line
    lineDataset.data.push({ x: new Date(selectedDateRange.start), y: 0 });
    lineDataset.data.push({ x: new Date(selectedDateRange.stop), y: 0 });
  }

  return lineDataset;
};

// Get default chart options based on the selected dateRange.
export const getDefaultOptions = (dateRange, minify = false) => {
  if (!dateRange) return null;
  let timeUnit, displayFormat;

  // Determine time unit and display format based on dateRange.
  switch (dateRange.id) {
    case DATE_RANGES.DAY:
      timeUnit = 'hour';
      displayFormat = minify ? { hour: 'MM/DD' } : { hour: 'MMM D, YYYY HH:mm' };
      break;
    case DATE_RANGES.WEEK:
    case DATE_RANGES.MONTH:
      timeUnit = 'day';
      displayFormat = { day: 'MMM D, YYYY' };
      break;
    case DATE_RANGES.YEAR:
      timeUnit = 'month';
      displayFormat = { month: 'MMM YYYY' };
      break;
    default:
      console.warn('Unrecognized date range option.');
      timeUnit = 'undefined';
      displayFormat = { day: 'MMM D, YYYY' };
  }

  // Return an object with chart configuration options.
  return {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        position: 'bottom',
        grid: {
          display: !minify,
        },
        time: {
          unit: timeUnit,
          displayFormats: displayFormat,
        },
        title: {
          display: false,
          text: 'Time',
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: !minify,
        },
        title: {
          display: !minify,
          text: 'Duration',
        },
      },
    },
    plugins: {
      drawOf: true,
      legend: {
        display: !minify,
        labels: {
          filter: function (item) {
            return item.text !== 'Hidden Legend Dataset';
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += `Duration: ${context.parsed.y}`;
            }
            if (context.parsed.x !== null) {
              const date = new Date(context.parsed.x);
              label += `, Date: ${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
            }
            return label;
          },
        },
      },
    },
  };
};

// Function to merge datasets by their chartId
export const mergeDatasetsByChartId = (allDatasets) => {
  let acc = {};
  allDatasets.forEach((dataset) => {
    const chartId = dataset.chartId;
    if (!acc[chartId]) {
      acc[chartId] = [dataset];
    } else {
      acc[chartId].push(dataset);
    }
  });
  return acc;
};

export const splitPointsBySubtype = (allPoints) => {
  return allPoints.reduce((points, item) => {
    if (!points[item.subtype]) points[item.subtype] = [];

    points[item.subtype].push(item);

    return points;
  }, {});
};
