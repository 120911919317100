import { useMemo, useState } from 'react';
import { formatSecondsToMinutesSeconds } from "../../../../../helpers/date/date-helper";
import { usePatternCreator } from "../../../../../contexts/PatternCreatorContext";

export const PatternEventTypesPanel = ({ patternEventTypes }) => {
  const { selectedPatternEventTypeId, setSelectedPatternEventTypeId } = usePatternCreator();

  return (
    <div className='h-full group/btn'>
      <div className='flex flex-col gap-2'>
        {patternEventTypes.sort((a, b) => a.time - b.time)?.map((patternEventType) => (
          <PatternEventTypeRow
            key={patternEventType.id}
            patternEventType={patternEventType}
          />
        ))}
      </div>
      {/* TODO Check if needed */}
      {/*<div className='mt-4'>*/}
      {/*  <HoverTextButton disabled={false}>*/}
      {/*    <PlusIcon className='w-4 h-4' />*/}
      {/*    <span className='pl-2'>Edit pattern</span>*/}
      {/*  </HoverTextButton>*/}
      {/*</div>*/}
    </div>
  );
};

const PatternEventTypeRow = ({ patternEventType }) => {
  const { updateProgress, selectedPatternEventTypeId, setSelectedPatternEventTypeId } = usePatternCreator();

  const patternEventTypeTime = formatSecondsToMinutesSeconds(patternEventType.time);

  const patternEventTypeStyle = useMemo(() => {
    const isActive = patternEventType?.id === selectedPatternEventTypeId;
    return isActive ? 'text-perception-blue font-semibold' : 'text-perception-gray-500';
  }, [selectedPatternEventTypeId]);

  const handleClick = () => {
    setSelectedPatternEventTypeId(patternEventType.id);
    updateProgress(patternEventType.time);
  };

  return (
    <div className='flex items-center justify-between cursor-pointer gap-2 text-xs text-center'
      onClick={() => handleClick()}>
      <div className={`${patternEventTypeStyle} material-icons`}>chevron_right</div>
      <div className={`${patternEventTypeStyle} uppercase`}>{patternEventType.event_type.content}</div>
      <div className='text-right text-xs'>{patternEventTypeTime}</div>
    </div>
  );
};
