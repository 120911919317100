import { useMemo } from 'react';
import { formatSecondsToMinutesSeconds } from "../../../../../helpers/date/date-helper";
import { usePatternCreator } from "../../../../../contexts/PatternCreatorContext";
import { PHASES_TEXTS } from "../../../../../services/live-interface/lca-interface-service";

export const PatternPhasesPanel = ({ patternPhases }) => {
  return (
    <div className='h-full group/btn'>
      <div className='flex flex-col gap-2'>
        {patternPhases.filter((phase) => phase.type > 0).sort((a, b) => a.start_date - b.start_date)?.map((patternPhase) => (
          <PatternPhaseRow
            key={patternPhase.id}
            patternPhase={patternPhase}
          />
        ))}
      </div>
    </div>
  );
};

const PatternPhaseRow = ({ patternPhase }) => {
  const { updateProgress, selectedPatternPhaseId, setSelectedPatternPhaseId } = usePatternCreator();

  const patternPhaseTime = formatSecondsToMinutesSeconds(patternPhase.start_date);

  const patternPhaseStyle = useMemo(() => {
    const isActive = patternPhase?.id === selectedPatternPhaseId;
    return isActive ? 'text-perception-blue font-semibold' : 'text-perception-gray-500';
  }, [selectedPatternPhaseId]);

  const handleClick = () => {
    setSelectedPatternPhaseId(patternPhase.id);
    updateProgress(patternPhase.start_date);
  };

  const phaseText = PHASES_TEXTS[patternPhase.type];

  return (
    <div className='flex items-center justify-between cursor-pointer gap-2 text-xs text-center'
      onClick={() => handleClick()}>
      <div className={`${patternPhaseStyle} material-icons`}>chevron_right</div>
      <div className={`${patternPhaseStyle} uppercase`}>{phaseText}</div>
      <div className='text-right text-xs'>{patternPhaseTime}</div>
    </div>
  );
};
