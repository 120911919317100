import ReactPlayer from "react-player";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import { usePatternCreator } from "../../contexts/PatternCreatorContext";
import { useState, useRef, useEffect } from "react";
import { ExclamationCircleIcon, PauseIcon, PlayIcon } from "@heroicons/react/20/solid";

export const VideoPlayer = ({ videoUrl, setFrames, handleOnProgress, handleVideoLoadedWithDuration, handleOnError }) => {

  const { playerRef, updateProgress, duration, setDuration } = usePatternCreator();

  const [errorMessage, setErrorMessage] = useState(null);

  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const progressRef = useRef(null);
  const [isProgressDragging, setIsProgressDragging] = useState(false);

  const extractAllFrames = async (videoFile, videoDuration, numFrames) => {
    const ffmpeg = createFFmpeg({ log: true });
    await ffmpeg.load();

    // Load the video into the virtual filesystem
    ffmpeg.FS('writeFile', 'input.mp4', await fetchFile(videoFile));

    // Calculate the interval in seconds between frames
    const interval = videoDuration / numFrames;
    console.log(interval);

    // Loop to extract frames at regular intervals
    for (let i = 0; i < numFrames; i++) {
      const time = (i * interval).toFixed(2); // Time in seconds as "XX.XX"
      console.log(time);

      // Run FFmpeg to extract a single frame
      await ffmpeg.run(
        '-ss', time,          // Fast seek to the specified time
        '-i', 'input.mp4',    // Input video
        '-frames:v', '1',     // Extract one frame
        '-an',                // Disable audio processing
        '-q:v', '2',          // Set high-quality for the frame
        `output_${i + 1}.jpg` // Output frame file
      );
    }

    // Read extracted frames and store them as Blobs
    const frames = [];
    for (let i = 0; i < numFrames; i++) {
      const frameData = ffmpeg.FS('readFile', `output_${i + 1}.jpg`);
      const blob = new Blob([frameData.buffer], { type: 'image/jpeg' });
      frames.push(URL.createObjectURL(blob)); // Generate a temporary URL
    }

    // Extract all frames
    // await ffmpeg.run('-i', 'input.mp4', '-filter:v', 'fps=fps=1/1', 'frame_%04d.png'); // Good one
    // await ffmpeg.run('-i', 'input.mp4', '-vf', 'select', '-vsync', 'vfr', 'frame_%04d.png');
    // await ffmpeg.run('-i', 'input.mp4', '-vf', 'fps=1/1', 'frame_%04d.png');
    // await ffmpeg.run('-i', 'input.mp4', 'frame_%04d.png');

    // Get all generated frames
    // const files = ffmpeg.FS('readdir', '/').filter((file) => file.startsWith('frame_') && file.endsWith('.png'));

    // Convert frames to URLs
    // const frames = files.map((file) => {
    //   // const data = ffmpeg.FS('readFile', file);
    //   // return URL.createObjectURL(new Blob([file.buffer], { type: 'image/png' }));
    // });

    console.log(frames);
    return frames; // Returns an array of URLs for the frames
  };

  /* [NOT USED] Load the video from the video URL - Used to extract frame, unavailable at the moment */
  // const [video, setVideo] = useState(null);
  // useEffect( () => {
  //   if(video !== null)
  //     return;
  //
  //   const loadVideo = async () => {
  //     const response = await fetch(videoUrl);
  //     if (!response.ok) {
  //       throw new Error('Failed to fetch video');
  //     }
  //     setVideo(await response.blob());
  //   };
  //
  //   loadVideo();
  // }, [videoUrl]);

  const onDuration = (duration) => {
    setDuration(duration);

    if (handleVideoLoadedWithDuration !== null && handleVideoLoadedWithDuration !== undefined)
      handleVideoLoadedWithDuration(duration);

    /* [NOT USED] Used to extract frames, not available at the moment */
    // TODO Manage this point
    // if (video === null)
    //   throw new Error('Video is null.');
    //
    // setFrames(await extractAllFrames(video, duration, 10));
  };

  const onProgress = (progress) => {
    if (handleOnProgress !== null && handleOnProgress !== undefined)
      handleOnProgress(progress);

    setProgress(progress.played);
  };

  const onError = (error) => {
    handleOnError(error);
    setErrorMessage('Une erreur est survenue lors du chargement de la vidéo.');
  };

  const handlePlayPause = (e) => {
    e.stopPropagation();
    setPlaying((prev) => !prev);
  };

  const handleProgressMouseDown = (e) => {
    setIsProgressDragging(true);
    handleProgressClick(e);
  };

  const handleProgressMouseMove = (e) => {
    if (isProgressDragging) {
      handleProgressClick(e);
    }
  };

  const handleProgressMouseUp = () => {
    setIsProgressDragging(false);
  };

  const handleProgressClick = (e) => {
    const rect = progressRef.current.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const newTime = Math.min(Math.max(0, (offsetX / rect.width) * duration), duration);
    updateProgress(newTime);
  };

  useEffect(() => {
    if (isProgressDragging) {
      document.addEventListener('mousemove', handleProgressMouseMove);
      document.addEventListener('mouseup', handleProgressMouseUp);
    } else {
      document.removeEventListener('mousemove', handleProgressMouseMove);
      document.removeEventListener('mouseup', handleProgressMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleProgressMouseMove);
      document.removeEventListener('mouseup', handleProgressMouseUp);
    };
  }, [isProgressDragging]);

  return (
    <>
      {
        errorMessage ?
          <>
            <div className='w-full h-full flex flex-col justify-center items-center text-center'>
              <ExclamationCircleIcon className="w-16 h-16 text-error"/>
              <div className='text-error'>{errorMessage}</div>
            </div>
          </>
        :
          <div className="relative h-full w-full bg-black" onClick={handlePlayPause}>
            <ReactPlayer
              ref={playerRef}
              url={videoUrl}
              controls={false}
              playing={playing}
              onDuration={onDuration}
              onProgress={onProgress}
              progressInterval={500}
              width="100%"
              height="100%"
              onReady={() => setIsReady(true)}
              onError={onError}
              muted={true}
            />
            {
              // Controls
              isReady &&
                <div className="absolute bottom-0 left-0 z-20 flex items-center gap-4 w-full px-4 py-2">
                  <button className="bg-perception-blue p-2 rounded-full" onClick={handlePlayPause}>
                    {
                      playing ?
                        <PauseIcon className="w-4 h-4 text-white"/> :
                        <PlayIcon className="w-4 h-4 text-white"/>
                    }
                  </button>

                  <div className="flex-1 h-2 bg-white/50 rounded-full overflow-hidden cursor-pointer" ref={progressRef}
                       onClick={(e) => e.stopPropagation()} onMouseDown={handleProgressMouseDown}>
                    <div className="h-full bg-perception-blue rounded-full" style={{width: `${progress * 100}%`}}/>
                  </div>
                </div>
            }
          </div>
      }
    </>
  );
};