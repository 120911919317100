import { useState } from "react";
import { TimelineVideo } from "../TimelineVideo";
import { usePatternCreator } from "../../../../../contexts/PatternCreatorContext";
import { patchData } from "../../../../../services/api/api-tools";
import { useAlert } from "../../../../../contexts/AlertContext";
import { VideoPlayer } from "../../../../../components/others/VideoPlayer";
import {
  isPatternAnnotationMode,
  PATTERNS_ANNOTATION_MODE_COMMENTS_ONLY, PATTERNS_ANNOTATION_MODE_PHASES, PATTERNS_ANNOTATION_MODE_STEPS
} from "../../../../../services/live-interface/patterns-annotation-service";

export const FrameViewer = ({ pattern }) => {

  const { setDuration, setProgress, selectedPatternEventTypeId, selectedPatternPhaseId,
    setSelectedPatternEventTypeId, setSelectedPatternPhaseId, selectedPatternEventType,
    selectedPatternPhase, comment, setComment, patternComment, setPatternComment } = usePatternCreator();

  const videoUrl = `${process.env.REACT_APP_NODE_EVENTS_PROTOCOL}://` +
    `${process.env.REACT_APP_NODE_EVENTS_URL}/videos/${pattern.id}.mp4`;

  /* Frames to display in the timeline - Shared between VideoPlayer (frames extraction) and TimelineVideo (frames view) */
  const [frames, setFrames] = useState([]);
  const { showAlert } = useAlert();

  const handleVideoLoadedWithDuration = (duration) => {
    const patternDuration = pattern.pattern_phases.length > 0 ?
      pattern.pattern_phases[pattern.pattern_phases.length - 1].end_date - pattern.pattern_phases[0].start_date : 0;
    setDuration(Math.max(duration, patternDuration));
  };

  const handleVideoLoadingError = (error) => {
    const patternDuration = pattern.pattern_phases.length > 0 ?
      pattern.pattern_phases[pattern.pattern_phases.length - 1].end_date - pattern.pattern_phases[0].start_date : 0;
    setDuration(patternDuration);
  };

  const handleOnProgress = (progress) => {
    setProgress(progress.playedSeconds);

    // Using event types
    if (isPatternAnnotationMode(PATTERNS_ANNOTATION_MODE_STEPS)){
      let patternEventTypeIdToSelect = null;
      for(const patternEventType of pattern.pattern_event_types){
        if(progress.playedSeconds - 2 <= patternEventType.time && patternEventType.time <= progress.playedSeconds + 2){
          patternEventTypeIdToSelect = patternEventType.id;
          break;
        }
      }
      setSelectedPatternEventTypeId(patternEventTypeIdToSelect);
    }

    // Using phases
    else if (isPatternAnnotationMode(PATTERNS_ANNOTATION_MODE_PHASES)){
      let patternPhaseIdToSelect = null;
      for(const patternPhase of pattern.pattern_phases.filter((phase) => phase.type > 0)){
        if(patternPhase.start_date <= progress.playedSeconds && progress.playedSeconds <= patternPhase.end_date){
          patternPhaseIdToSelect = patternPhase.id;
          break;
        }
      }
      setSelectedPatternPhaseId(patternPhaseIdToSelect);
    }
  };

  const updateComment = async () => {
    try {
      let response = null;

      // Using event types
      if (isPatternAnnotationMode(PATTERNS_ANNOTATION_MODE_STEPS)){
        response = await patchData(`pattern_event_types/${selectedPatternEventTypeId}`, { comment: comment });
        selectedPatternEventType.comment = comment;
      }
      // Using phases
      else if (isPatternAnnotationMode(PATTERNS_ANNOTATION_MODE_PHASES)){
        response = await patchData(`pattern_phases/${selectedPatternPhaseId}`, { comment: comment });
        selectedPatternPhase.comment = comment;
      }

      if (response) {
        showAlert('success', 'Les informations ont été mises à jour.');
      } else {
        showAlert('warning', 'Oops! An error occurred.');
      }
    } catch (err) {
      console.error('Something bad happened:', err);
    }
  };

  const updatePatternComment = async () => {
    try {
      const response = await patchData(`patterns/${pattern.id}/info`, { commentary: patternComment });
      if (response) showAlert('success', 'Les informations ont été mises à jour.');
      else showAlert('warning', 'Oops! An error occurred.');
    } catch (err) {
      console.error('Something bad happened:', err);
    }
  };

  return (
    <div className='h-full flex items-center justify-between overflow-hidden'>
      <div className={`h-full flex flex-col items-center
          ${isPatternAnnotationMode(PATTERNS_ANNOTATION_MODE_COMMENTS_ONLY) ? 'w-full' : 'w-2/3'}`}>
        <div className="h-3/4 w-full flex items-center p-4 pb-0">
          <VideoPlayer
            videoUrl={videoUrl}
            setFrames={setFrames}
            handleVideoLoadedWithDuration={handleVideoLoadedWithDuration}
            handleOnError={handleVideoLoadingError}
          />
        </div>

        <div className="w-full h-1/4">
          {
            isPatternAnnotationMode(PATTERNS_ANNOTATION_MODE_COMMENTS_ONLY) ?
              <div className="h-full p-4">
                <textarea
                  className='w-full h-full resize-none bg-perception-black-800 p-4 focus:outline-none placeholder-perception-gray-700'
                  placeholder={`Ajoutez un message à montrer à l'opérateur`}
                  value={patternComment}
                  onChange={(e) => setPatternComment(e.target.value)}
                  onBlur={updatePatternComment}
                />
              </div>
            :
              <div className="h-full flex flex-col justify-end">
                <TimelineVideo
                  frames={frames}
                  patternEventTypes={pattern.pattern_event_types}
                  patternPhases={pattern.pattern_phases}
                  onProgress={handleOnProgress}
                />
              </div>
          }
        </div>
      </div>

      {
        !isPatternAnnotationMode(PATTERNS_ANNOTATION_MODE_COMMENTS_ONLY) &&
          <div className='w-1/3 h-full'>
            {selectedPatternEventTypeId || selectedPatternPhaseId ?
              <textarea
                className='w-full h-full resize-none bg-perception-black-800 p-4 focus:outline-none placeholder-perception-gray-700'
                placeholder={`Ajoutez un message à montrer à l'opérateur`}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                onBlur={updateComment}
              /> :
              <div className='w-full h-full flex flex-col justify-center items-center'>
                <div className='text-4xl material-icons text-perception-black-800'>info</div>
                <div className='text-perception-gray-800'>No process information to display</div>
              </div>
            }
          </div>
      }
    </div>
  );
};
