import { useState, useEffect } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const InputSelect = ({
  className,
  items,
  onChange,
  itemToString,
  defaultValue = null,
  disabled = false,
  category = 'primary',
  value = null,
  ...props
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (defaultValue) {
      setSelectedItem(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (value !== undefined && value !== null && value !== selectedItem) {
      setSelectedItem(value);
    }
  }, [value]);

  const filteredItems =
    searchTerm === ''
      ? items
      : items.filter((item) => itemToString(item).toLowerCase().includes(searchTerm.toLowerCase()));

  const handleItemChange = (item) => {
    if (item !== selectedItem) {
      setSelectedItem(item);
      if (onChange) {
        onChange(item);
      }
    }
  };

  const getInputStyle = () => {
    const basesClass =
      'w-full bg-transparent cursor-pointer border-0 outline-0 py-1.5 pl-3 text-xs uppercase select-none';

    if (category === 'primary') {
      return `text-white ${className} ${basesClass}`;
    } else if (category === 'secondary') {
      return `text-perception-gray-800 ${className} ${basesClass}`;
    }
  };

  const getButtonStyle = () => {
    const basesClass = 'relative w-full cursor-pointer';

    if (disabled) {
      return `bg-perception-black-500 focus:outline-none ${basesClass}`;
    } else if (category === 'primary') {
      return `bg-perception-gray-800 focus:outline-none ${basesClass}`;
    } else if (category === 'secondary') {
      return `bg-transparent border border-perception-gray-500 border-black ${basesClass}`;
    }
  };

  useEffect(() => {
    if (defaultValue !== selectedItem) {
      setSelectedItem(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Combobox as='div' value={value ?? selectedItem} onChange={handleItemChange}>
      <Combobox.Button className={getButtonStyle()} disabled={disabled}>
        <div className='flex items-center justify-between'>
          <Combobox.Input
            className={getInputStyle()}
            onChange={(event) => setSearchTerm(event.target.value)}
            displayValue={(item) => itemToString(item)}
            {...props}
            onClick={() => {
              if (disabled) {
                return;
              }
            }}
          />
          {!disabled && <ChevronUpDownIcon className='h-5 w-5 text-gray-400 mr-2' aria-hidden='true' />}
        </div>

        {filteredItems?.length > 0 && !disabled && (
          <Combobox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-perception-gray-800 py-1 shadow-lg focus:outline-none text-xs'>
            {filteredItems.map((item) => (
              <Combobox.Option
                key={item.id}
                value={item}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-perception-blue text-white' : 'text-white',
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate text-start', selected && 'font-semibold')}>
                      {itemToString(item)}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-perception-blue',
                        )}
                      >
                        <CheckIcon className='h-5 w-5' aria-hidden='true' />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </Combobox.Button>
    </Combobox>
  );
};
