import { Panel } from "../../components/panels/Panel";
import { useState } from "react";
import { SpinnerIcon } from "../../components/loading/SpinnerIcon";
import { postData } from "../../services/api/api-tools";
import { useSocket } from "../../contexts/SocketContext";
import { useEffect, Fragment } from "react";
import { useAlert } from "../../contexts/AlertContext";

export const NewProcessPage = () => {
  const { socket, subscribeToEvent, unsubscribeFromEvent, sendEvent } = useSocket();
  const { showAlert } = useAlert();

  const [patternName, setPatternName] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [startRecordingDate, setStartRecordingDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasFirstContact, setHasFirstContact] = useState(false); // Used for Hermes
  const [currentData, setCurrentData] = useState({});

  const handleStartRecording = async () => {
    if (patternName === ''){
      showAlert('warning', 'Veuillez entrer un nom pour cette gamme.');
      return;
    }

    setIsRecording(true);
    setHasFirstContact(false);
    setStartRecordingDate(Math.floor(Date.now() / 1000));

    /* Send pattern data to the engine */
    // TODO Manage pattern insertion + pattern id
    if (process.env.REACT_APP_PATTERN_RECORDING_USE_EXTERNAL_SERVICE === 'false'){
      try {
        const patternBody = {
          name: patternName,
          is_active: true,
          last_modified: '1970-01-01 00:00:00',
          commentary: '',
          workstation_id: 1,
        }
        const response = await postData('patterns', patternBody);
        sendEvent('selected_pattern', { step: 'recording', id: response.id });
      } catch (error) {
        console.error('Error creating new pattern in database.', error);
        return null;
      }
    }
    else{
      sendEvent('selected_pattern', { step: 'recording' });
    }
  };

  const handleStopRecording = () => {
    setIsRecording(false);
    setIsLoading(true);

    /* Send fake pattern data to the engine */
    sendEvent('selected_pattern', { step: 'stop', pieces_number: -2 });

    if (process.env.REACT_APP_PATTERN_RECORDING_USE_EXTERNAL_SERVICE === 'true'){
      const patternRecordingBody = {
        start_date: startRecordingDate,
        end_date: Math.floor(Date.now() / 1000),
        name: patternName,
        type: parseInt(process.env.REACT_APP_PATTERNS_ANNOTATION_MODE)
      };
      postData('pattern_recordings', patternRecordingBody);
    }

    setTimeout(() => {
      window.location = '/processes';
    }, 30000);
  };

  /* Receive message during recording */
  useEffect(() => {
    if (!socket) return;

    const handleReceivedData = (data) => {
      console.log(data);
      if (isRecording){
        setHasFirstContact(data?.first_contact);
        setCurrentData(prevData => ({...prevData, ...data}));
      }
    };

    subscribeToEvent('current_data', handleReceivedData);

    return () => {
      unsubscribeFromEvent('current_data', handleReceivedData);
    };
  }, [socket, isRecording, setHasFirstContact, hasFirstContact]);

  /* Ping to the engine - Specific to Hermes */
  useEffect(() => {
    if (process.env.REACT_APP_CUSTOMER_NAME !== 'Hermes') return;
    if (!socket) return;
    const interval = setInterval(() => {
      if (isRecording) {
        sendEvent('selected_pattern', { step: 'ping' });
      }
    }, 500);
    return () => clearInterval(interval);
  }, [isRecording, socket]);

  return (
    <div className='flex flex-col w-full h-full'>
      <Panel title='NOUVELLE GAMME'>
        <div className='h-full flex flex-col justify-center items-center text-3xl gap-8'>
          {
            isLoading ?
              <>
                <SpinnerIcon />
                Analyse en cours. Cela peut prendre plusieurs minutes.
              </>
            : !isRecording ?
              <>
                <p className="mb-16">
                  Nom de la gamme :
                  <input
                    type="text"
                    placeholder="Entrez le nom de la gamme"
                    value={patternName}
                    onChange={(e) => setPatternName(e.target.value)}
                    className="text-xl w-96 text-perception-gray-500 ml-4 p-4 bg-perception-gray-800 outline-none" />
                </p>
                <div className="relative w-[150px] h-[150px] cursor-pointer select-none" onClick={handleStartRecording}>
                  <div className="absolute rounded-full w-full h-full bg-gray-500 flex justify-center text-white items-center hover:bg-red-500">
                    REC
                  </div>
                </div>
                <p>Démarrer l'enregistrement</p>
              </>
            :
              <>
                <div className="relative w-[150px] h-[150px] cursor-pointer select-none" onClick={handleStopRecording}>
                  <div className="absolute animate-ping rounded-full bg-red-500 w-[100px] h-[100px] m-[25px]"/>
                  <div className="absolute rounded-full w-full h-full bg-red-500 flex justify-center text-white items-center">
                    STOP
                  </div>
                </div>
                <p className="animate-pulse">Enregistrement en cours...</p>

                {/* TODO [WIP] This message should be optional */}
                {
                  process.env.REACT_APP_CUSTOMER_NAME === 'Hermes' &&
                    <div className="h-48 mt-12 flex justify-center items-center">
                      {
                        !hasFirstContact ?
                          <div className='text-perception-gray-700'>En attente d'un point de contact.</div>
                          :
                          <div className='text-perception-gray-700'>Points de contact détectés. Continuez l'opération.</div>
                      }
                    </div>
                }
                {
                  process.env.REACT_APP_CUSTOMER_NAME === 'LCA' &&
                  <div className="h-48 mt-12 flex justify-center items-center">
                    {
                      currentData?.info ?
                        <div className='text-perception-gray-700'>
                          {currentData.info.split('\n').map((line, index) => (
                            <Fragment key={index}>{line}<br/></Fragment>
                          ))}
                        </div>
                        :
                        <div className='text-perception-gray-700'>Continuez de réaliser les opérations.</div>
                    }
                  </div>
                }
              </>
          }
        </div>
      </Panel>
    </div>
  );

};
