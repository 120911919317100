import { InputSelect } from '../../../../components/forms/inputs/InputSelect';
import { useOrgData } from '../../../../contexts/OrgDataContext';
import { Panel } from '../../../../components/panels/Panel';

export const SidePatternsPanel = ({ workshopSelected, setWorkshopSelected }) => {
  const { workshops } = useOrgData();

  return (
    <div className='flex flex-col w-full h-full'>
      <Panel title='WORKSHOPS'>
        <InputSelect
          items={workshops}
          onChange={(item) => setWorkshopSelected(item)}
          itemToString={(item) => item?.name}
          defaultValue={workshopSelected}
        />
      </Panel>
    </div>
  );
};
