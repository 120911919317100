import { Annotation } from './components/annotation/Annotation';
import { Loader } from '../../components/loading/Loader';
import { usePatternCreator } from "../../contexts/PatternCreatorContext";

export const ProcessPage = () => {

  const { pattern, patternIsLoading } = usePatternCreator();

  if (patternIsLoading) return <Loader category='transparent' />;

  if(pattern === undefined)
    return <div>Pattern not found.</div>

  return (
    <div className='flex w-full h-full'>
      <Annotation
        pattern={pattern}
      />
    </div>
  );
};
