import { useEffect } from 'react';
import { Fragment } from 'react';
import { SidePatternsPanel } from './components/processes-list/SidePatternsPanel';
import { MainPatternsPanel } from './components/processes-list/MainPatternsPanel';
import { useOrgData } from '../../contexts/OrgDataContext';
import { useGetData } from '../../services/api/api-tools';

export const ProcessesPage = () => {
  const { workshopSelected, setWorkshopSelected } = useOrgData();

  const patterns = useGetData(
    'patterns',
    'patterns',
    // TODO Add parameters to manage workstations
  );

  return (
    <Fragment>
      <div className='flex h-full'>
        <div className='w-1/6'>
          <SidePatternsPanel workshopSelected={workshopSelected} setWorkshopSelected={setWorkshopSelected} />
        </div>
        <MainPatternsPanel patterns={patterns} />
      </div>
    </Fragment>
  );
};
