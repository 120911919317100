export const PHASE_NONE = 0;
export const PHASE_GLUEING = 1;
export const PHASE_STRASS = 2;
export const PHASE_OTHER = 3;
export const PHASE_CHECK = 4;

export const PHASES_TEXTS = {
  [PHASE_NONE]: 'Aucune',
  [PHASE_GLUEING]: 'Collage',
  [PHASE_STRASS]: 'Strassage',
  [PHASE_OTHER]: 'Opération autre',
  [PHASE_CHECK]: 'Contrôle'
};

export const PHASES_COLORS = {
  [PHASE_NONE]: 'bg-gray-500',
  [PHASE_GLUEING]: 'bg-green-500',
  [PHASE_STRASS]: 'bg-perception-blue',
  [PHASE_OTHER]: 'bg-pink-500',
  [PHASE_CHECK]: 'bg-gray-500',
  error: 'bg-yellow-500'
}