import { Modal } from '../../../../../components/modals/Modal';

export const WarnModal = ({ isOpen, setIsWarnModalOpen, onConfirmDelete }) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsWarnModalOpen}>
      <div className='p-4'>
        <h3 className='text-lg text-start font-medium leading-6 text-gray-900'>Confirm deletion</h3>
        <div className='my-4'>
          <p className='text-sm text-start text-gray-500'>
            Are you sure you want to delete this item? <br /> This action is irreversible.
          </p>
        </div>
        <div className='flex justify-start'>
          <button
            className='inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50'
            onClick={() => setIsWarnModalOpen(false)}
          >
            Cancel
          </button>
          <button
            className='ml-4 inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700'
            onClick={() => {
              onConfirmDelete();
              setIsWarnModalOpen(false);
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </Modal>
  );
};
