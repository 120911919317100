import { Line } from "react-chartjs-2";
import { Chart } from "chart.js";
import { useEffect, useState } from "react";

const customBackgroundPlugin = {
  id: 'customBackgroundPlugin',
  beforeDraw: (chart) => {
    const ctx = chart.ctx;
    const { top, left, right } = chart.chartArea;
    const yAxis = chart.scales.y;
    const y100 = yAxis.getPixelForValue(100);
    const y80 = yAxis.getPixelForValue(80);

    ctx.save();
    ctx.fillStyle = 'rgb(190,255,190)';
    ctx.fillRect(left, y100, right - left, y80 - y100);
    ctx.restore();
  },
};

Chart.register(customBackgroundPlugin);

export const Graph = ({ points }) => {

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: { display: false },
        grid: { display: false }
      },
      y: {
        grid: { display: false },
        min: 30,
        max: 100,
        ticks: { callback: (value) => value + '%' }
      }
    },
    maintainAspectRatio: false
  };

  const data = {
    labels: Array.from({ length: points?.length > 0 ? points.length : 0 }, () => ''),
    datasets: [
      {
        label: 'Score',
        data: points,
        borderColor: 'rgb(65,100,253)',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        tension: 0.3,
        fill: true,
        pointRadius: 0,
        borderWidth: 5,
      }
    ],
  };

  return (
    <div className="w-full h-full">
      <Line
        data={data}
        options={options}
        updateMode='none'
      />
    </div>
  );
};