import { Panel } from '../../components/panels/Panel';
import { DonutChart } from "../dashboard/components/charts/DonutChart";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { graphColors } from "../../services/entity/chart/chart-service";
import { Link } from "react-router-dom";

/* More info about charts used in this dashboard:
 * https://www.chartjs.org/docs/latest/
 */

const generateDataPoints = (numPoints, startTimestamp, endTimestamp, duration, peeksTimestamp, h) => {
  const data = [];
  const interval = (endTimestamp - startTimestamp) / (numPoints - 1);

  const values = Array(numPoints).fill(0);

  for(const peekData of peeksTimestamp){
    const [peek, peekLevel] = peekData;
    let maxDiff = 3600 * 4 * h;

    for (let i = 0; i < numPoints; i++) {
      const timestamp = startTimestamp + i * interval;

      let value = Math.abs(timestamp - peek) < duration ? 0 : Math.abs(timestamp - peek);
      value = peekLevel * Math.max(0, 1 - value / maxDiff);

      values[i] += value;
    }
  }

  for (let i = 0; i < numPoints; i++) {
    values[i] = values[i] * (Math.random() * 0.2 + 0.9);
    values[i] = Math.max(0, values[i]) + Math.random() * 0.15 + 0.05;
  }

  const maxValue = Math.max(1.1, Math.max(...values) * (Math.random() * 0.2 + 1));
  for (let i = 0; i < numPoints; i++) {
    const timestamp = startTimestamp + i * interval;
    values[i] = values[i] / maxValue;
    data.push({ x: Math.floor(timestamp * 1000), y: values[i] * 100 });
  }

  return data;
}

const options = {
  maintainAspectRatio: false,
  scales: {
    x: {
      type: 'time',
      position: 'bottom',
      grid: {
        display: true,
      },
      time: {
        unit: 'hour',
        displayFormats: { hour: 'MMM D, YYYY HH:mm' },
      },
      ticks: {
        stepSize: 6,
      },
      title: {
        display: false,
        text: 'Time',
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        display: true,
      },
      title: {
        display: false,
        text: '%',
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

const donutOptions = {
  cutout: '80%',
  plugins: {
    legend: false,
  },
};

export const ErgonomicsPage = () => {

  let startTime = new Date();
  startTime.setHours(0, 0, 0, 0);
  startTime = startTime.getTime();

  let endTime = new Date(startTime + 24 * 3600 * 1000);
  endTime = endTime.getTime();

  startTime = Math.floor(startTime / 1000);
  endTime = Math.floor(endTime / 1000);

  const seriesData1 = generateDataPoints(
    100, startTime, endTime, 3600,
    [[startTime + 10 * 3600, 1], [startTime + 18 * 3600, 0.5]], 0.5
  );

  const seriesDonut1 = {
    labels: ['Yes', 'No'],
    datasets: [
      {
        data: [27, 100 - 27],
        backgroundColor: [graphColors[0], 'rgba(67, 67, 67, 1)'],
        borderWidth: 0,
      },
    ],
  };

  const seriesData2 = generateDataPoints(
    100, startTime, endTime, 5000,
    [[startTime + 8 * 3600, 0.8], [startTime + 14 * 3600, 0.9], [startTime + 17 * 3600, 0.9]], 0.6
  );

  const seriesDonut2 = {
    labels: ['Yes', 'No'],
    datasets: [
      {
        data: [41, 100 - 41],
        backgroundColor: [graphColors[1], 'rgba(67, 67, 67, 1)'],
        borderWidth: 0,
      },
    ],
  };

  const seriesData3 = generateDataPoints(
    100, startTime, endTime, 2000,
    [[startTime + 6 * 3600, 0.3], [startTime + 9 * 3600, 0.5], [startTime + 12 * 3600, 0.7], [startTime + 15 * 3600, 0.4]], 0.3
  );

  const seriesDonut3 = {
    labels: ['Yes', 'No'],
    datasets: [
      {
        data: [16, 100 - 16],
        backgroundColor: [graphColors[3], 'rgba(67, 67, 67, 1)'],
        borderWidth: 0,
      },
    ],
  };

  const seriesData4 = generateDataPoints(
    100, startTime, endTime, 3000,
    [[startTime + 10 * 3600, 0.3], [startTime + 14 * 3600, 0.5], [startTime + 16 * 3600, 0.7]], 0.5
  );

  const seriesDonut4 = {
    labels: ['Yes', 'No'],
    datasets: [
      {
        data: [29, 100 - 29],
        backgroundColor: [graphColors[2], 'rgba(67, 67, 67, 1)'],
        borderWidth: 0,
      },
    ],
  };

  const seriesDonutSummary = {
    labels: ['Yes', 'No'],
    datasets: [
      {
        data: [14, 100 - 14],
        backgroundColor: ['rgba(65, 100, 253, 1)', 'rgba(67, 67, 67, 1)'],
        borderWidth: 0,
      },
    ],
  };

  return (
    <Panel category='main' title='ERGONOMICS'>
      <div className='h-full flex'>
        <div className='flex flex-col w-4/12 mr-4'>
          <div className='flex h-1/3 mb-4 p-4'>
            <div className='w-1/3 flex flex-col justify-center mx-4 text-center'>
              <span className='text-5xl mb-4'>16h 44</span>
              <span className='text-xl'>Durée totale de production</span>
            </div>
            <div className='w-1/3 flex flex-col justify-center mx-4 text-center'>
              <span className='text-5xl mb-4'>2</span>
              <span className='text-xl'>Alertes levées sur la journée</span>
            </div>
            <div className='w-1/3 flex flex-col justify-center items-center mx-4 text-center'>
              {/*<div className='w-32 w-32 relative mb-4'>*/}
              {/*  <Doughnut data={seriesDonutSummary} options={donutOptions} />*/}
              {/*  <div className='text-2xl absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>14%</div>*/}
              {/*</div>*/}
              <span className='text-5xl mb-4'>14%</span>
              <span className='text-xl'>Taux en situation dangereuse</span>
            </div>
          </div>
          <div className="flex h-2/3">
            <div className='h-full bg-perception-black-800 mb-4 p-4 overflow-y-auto'>
              <h2>Notifications</h2>
              <div className='flex flex-col items-center'>
                <span className='text-perception-black-700 text-8xl material-icons my-4'>notifications_off</span>
                <span className='text-perception-gray-700'>Pas de nouvelle notification</span>
              </div>
              <h2 className='mt-8'>Anciennes notifications</h2>
              <div className='border-l-4 bg-perception-black-700 border-l-red-500 p-4 my-4'>
                Le poste 3 montre une répétition excessive des mouvements des épaules. Une adaptation de l'équipement ou une pause supplémentaire est recommandée.
              </div>
              <div className='border-l-4 bg-perception-black-700 border-l-yellow-500 p-4 my-4'>
                L’opérateur au poste 7 maintient une posture inconfortable depuis 45 minutes. Pensez à vérifier l’ajustement de la hauteur du poste de travail.
              </div>
              <div className='border-l-4 bg-perception-black-700 border-l-yellow-500 p-4 my-4'>
                Les données du poste 5 indiquent une surcharge sur les épaules. Un ajustement des outils ou une rotation des tâches est conseillé.
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col w-3/12 mr-4'>
          <div className='h-full bg-perception-black-800 overflow-y-auto mb-4'>
            <table className='w-full'>
              <tr>
                <th className='p-4 text-left bg-perception-gray-800'>Membre</th>
                <th className='p-4 w-[50%] text-left bg-perception-gray-800 text-perception-gray-500' colSpan={2}>Score RULA observé</th>
              </tr>
              <tr>
                <td className='p-4'>Nuque</td>
                <td className='p-4 text-yellow-400'>4</td>
                <td className='pt-2 pr-4 text-yellow-400'><span className='material-icons'>report</span></td>
              </tr>
              <tr>
                <td className='p-4'>Tronc</td>
                <td className='p-4 text-yellow-400'>3</td>
                <td className='pt-2 pr-4 text-yellow-400'><span className='material-icons'>report</span></td>
              </tr>
              <tr>
                <td className='p-4'>Deltoïde gauche</td>
                <td className='p-4 text-red-400 font-bold'>5</td>
                <td className='pt-2 pr-4 text-red-400 font-bold'><span className='material-icons'>dangerous</span></td>
              </tr>
              <tr>
                <td className='p-4'>Deltoïde droit</td>
                <td className='p-4 text-red-400 font-bold'>6</td>
                <td className='pt-2 pr-4 text-red-400 font-bold'><span className='material-icons'>dangerous</span></td>
              </tr>
              <tr>
                <td className='p-4'>Coude gauche</td>
                <td className='p-4 text-green-400'>2</td>
                <td className='pt-2 pr-4 text-green-400'><span className='material-icons'>check</span></td>
              </tr>
              <tr>
                <td className='p-4'>Coude droit</td>
                <td className='p-4 text-yellow-400'>3</td>
                <td className='pt-2 pr-4 text-yellow-400'><span className='material-icons'>report</span></td>
              </tr>
              <tr>
                <td className='p-4'>Poignet gauche</td>
                <td className='p-4 text-green-400'>2</td>
                <td className='pt-2 pr-4 text-green-400'><span className='material-icons'>check</span></td>
              </tr>
              <tr>
                <td className='p-4'>Poignet droit</td>
                <td className='p-4 text-green-400'>2</td>
                <td className='pt-2 pr-4 text-green-400'><span className='material-icons'>check</span></td>
              </tr>
            </table>
          </div>
          <div className='h-1/3 bg-perception-black-800 p-4 overflow-y-auto'>
            <h2 className='mb-4'>Quick links</h2>
            <p className='cursor-pointer text-perception-blue mb-2'>• Rapports complets sur les données de posture</p>
            <p className='cursor-pointer text-perception-blue mb-2'>• Analyse détaillée des risques ergonomiques identifiés</p>
            <p className='cursor-pointer text-perception-blue mb-2'>• Paramètres d'optimisation des postes de travail</p>
          </div>
        </div>
        <div className='flex flex-col w-5/12'>
          <div className='h-full flex flex-col bg-perception-black-800 p-4 overflow-y-auto'>
            <h2 className='m-4 mt-0'>Mouvements épaule gauche</h2>
            <div className='flex h-1/4'>
              <div className='w-4/5 mr-4'>
                <Bar data={{label: 'Series 1', datasets: [{data: seriesData1, backgroundColor: graphColors[0]}]}} options={options}/>
              </div>
              <div className='flex flex-col items-center justify-center w-1/5'>
                <p className='mb-4'>Durée d'exposition</p>
                <div className='w-20 h-20 relative'>
                  <Doughnut data={seriesDonut1} options={donutOptions} />
                  <div className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>27%</div>
                </div>
              </div>
            </div>
            <h2 className='m-4 mt-8'>Mouvements épaule droite</h2>
            <div className='flex h-1/4'>
              <div className='w-4/5 mr-4'>
                <Bar data={{label: 'Series 1', datasets: [{data: seriesData2, backgroundColor: graphColors[1]}]}} options={options}/>
              </div>
              <div className='flex flex-col items-center justify-center w-1/5'>
                <p className='mb-4'>Durée d'exposition</p>
                <div className='w-20 h-20 relative'>
                  <Doughnut data={seriesDonut2} options={donutOptions} />
                  <div className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>41%</div>
                </div>
              </div>
            </div>
            <h2 className='m-4 mt-8'>Mouvements bras en hauteur</h2>
            <div className='flex h-1/4'>
              <div className='w-4/5 mr-4'>
                <Bar data={{label: 'Series 1', datasets: [{data: seriesData4, backgroundColor: graphColors[2]}]}} options={options}/>
              </div>
              <div className='flex flex-col items-center justify-center w-1/5'>
                <p className='mb-4'>Durée d'exposition</p>
                <div className='w-20 h-20 relative'>
                  <Doughnut data={seriesDonut4} options={donutOptions} />
                  <div className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>29%</div>
                </div>
              </div>
            </div>
            <h2 className='m-4 mt-8'>Mouvements nuque</h2>
            <div className='flex h-1/4'>
              <div className='w-4/5 mr-4'>
                <Bar data={{label: 'Series 1', datasets: [{data: seriesData3, backgroundColor: graphColors[3]}]}} options={options}/>
              </div>
              <div className='flex flex-col items-center justify-center w-1/5'>
                <p className='mb-4'>Durée d'exposition</p>
                <div className='w-20 h-20 relative'>
                  <Doughnut data={seriesDonut3} options={donutOptions} />
                  <div className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>16%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Panel>
  );
};
