import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useGetData } from "../services/api/api-tools";
import { useParams } from "react-router-dom";

export const PatternCreatorContext = createContext();

export const usePatternCreator = () => useContext(PatternCreatorContext);

export const PatternCreatorProvider = ({ children }) => {

  const { patternId } = useParams();

  // Retrieves information about the pattern
  // TODO Create back API filter
  const patternQueryKey = ['pattern', patternId];
  const {
    data: patterns,
    isLoading: patternIsLoading,
    refetch: refetchPattern,
  } = useGetData(patternQueryKey, `patterns?id=${patternId}`);

  const pattern = useMemo(() => {
    if(patterns === null || patterns === undefined || patterns.length === 0)
      return null;

    return patterns.find((pattern) => pattern.id === parseInt(patternId));
  }, [patterns]);

  const [selectedPatternEventTypeId, setSelectedPatternEventTypeId] = useState(null);
  const [selectedPatternPhaseId, setSelectedPatternPhaseId] = useState(null);
  const [progress, setProgress] = useState(null);
  const [duration, setDuration] = useState(null);
  const [comment, setComment] = useState('');
  const [patternComment, setPatternComment] = useState('');

  const playerRef = useRef(null);

  const updateProgress = (progress) => {
    if (playerRef.current){
      playerRef.current.seekTo(progress);
    }
    setProgress(progress);
  };

  useEffect(() => {
    if(pattern !== undefined && pattern !== null){
      setPatternComment(pattern.commentary);
    }
  }, [pattern]);

  const selectedPatternEventType = useMemo(() => {
    if(pattern === undefined || pattern === null)
      return undefined;

    return pattern.pattern_event_types.find((pet) => pet.id === selectedPatternEventTypeId);
  }, [patterns, selectedPatternEventTypeId]);

  const selectedPatternPhase = useMemo(() => {
    if(pattern === undefined || pattern === null)
      return undefined;

    return pattern.pattern_phases.find((pp) => pp.id === selectedPatternPhaseId);
  }, [patterns, selectedPatternPhaseId]);

  useEffect(() => {
    if(selectedPatternEventType !== null && selectedPatternEventType !== undefined)
      setComment(selectedPatternEventType.comment || '');
    else if(selectedPatternPhase !== null && selectedPatternPhase !== undefined)
      setComment(selectedPatternPhase.comment || '');
    else
      setComment('');
  }, [selectedPatternEventType, selectedPatternPhase]);

  const contextValue = useMemo(
    () => ({
      pattern, patternIsLoading, refetchPattern,
      selectedPatternEventTypeId, setSelectedPatternEventTypeId,
      selectedPatternPhaseId, setSelectedPatternPhaseId,
      selectedPatternEventType,
      selectedPatternPhase,
      progress, setProgress, updateProgress,
      duration, setDuration,
      playerRef,
      comment, setComment,
      patternComment, setPatternComment,
    }),
    [
      pattern, patternIsLoading, refetchPattern,
      selectedPatternEventTypeId, setSelectedPatternEventTypeId,
      selectedPatternPhaseId, setSelectedPatternPhaseId,
      selectedPatternEventType,
      selectedPatternPhase,
      progress, setProgress, updateProgress,
      duration, setDuration,
      playerRef,
      comment, setComment,
      patternComment, setPatternComment,
    ],
  );

  return <PatternCreatorContext.Provider value={contextValue}>{children}</PatternCreatorContext.Provider>;
};
