import { InputSelect } from '../../components/forms/inputs/InputSelect';
import { Button } from '../../components/buttons/Button';
import { useEffect, useRef, useState, Fragment } from "react";
import { postData, useGetData } from "../../services/api/api-tools";
import LogoPerception from '../../assets/images/logos/logo-blue-black.png';
import LogoHermes from '../../assets/images/logos/Tiffany_Logo.png';
import { useSocket } from "../../contexts/SocketContext";
import {
  ArrowUturnLeftIcon,
  InformationCircleIcon, PlayIcon, StopIcon,
  XMarkIcon
} from "@heroicons/react/20/solid";
import { useAlert } from "../../contexts/AlertContext";
import { formatSecondsToMinutesSeconds } from "../../helpers/date/date-helper";
import { Graph } from "./components/Graph";
import { VideoPlayer } from "../../components/others/VideoPlayer";

export const HermesUSLivePage = () => {

  const { socket, subscribeToEvent, unsubscribeFromEvent, sendEvent } = useSocket();
  const { showAlert } = useAlert();

  const { data: patterns } = useGetData('patterns', `patterns`);

  const POINTS_NUMBER = 200;

  const [selectedPattern, setSelectedPattern] = useState(null);
  const [isStarted, setIsStarted] = useState(false);
  const [points, setPoints] = useState([]);
  const [currentData, setCurrentData] = useState({});
  const [showSynthesis, setShowSynthesis] = useState(false);
  const [duration, setDuration] = useState(0);

  const videoUrl = `${process.env.REACT_APP_NODE_EVENTS_PROTOCOL}://` +
    `${process.env.REACT_APP_NODE_EVENTS_URL}/videos/${selectedPattern?.id}.mp4`;

  /* Placeholder to simulate new points */
  // useEffect(() => {
  //   let interval = setInterval(() => {
  //     console.log('add point');
  //     setPoints(points => {
  //       const newPoint = Math.floor(Math.random() * (100 - 80 + 1)) + 80;
  //       const updatedPoints = [...points, newPoint];
  //       return updatedPoints.slice(- (POINTS_NUMBER - 1));
  //     });
  //   }, 50);
  //
  //   return () => clearInterval(interval);
  // }, []);

  const lockedData = useRef(false);

  const handleStart = () => {
    if(selectedPattern !== null)
      setIsStarted(true);

    /* Send pattern data to the engine */
    console.log(selectedPattern);
    sendEvent('selected_pattern', { step: 'running', id: selectedPattern.id });
  };

  const resetInterface = () => {
    // This check is needed to prevent from resetting the interface twice
    if (showSynthesis) {
      setPoints([]);
      setCurrentData([]);
      setDuration(0);
      lockedData.current = false;
      setShowSynthesis(false);
      handleStart();
    }
  };

  useEffect(() => {
    if (!isStarted) return;
    if (!socket) return;

    const handleReceivedData = (data) => {
      if (lockedData.current) return;

      console.log(data);

      /* Manage similarity: register the point */
      if ('similarity' in data){
        setPoints(points => {
          const updatedPoints = [...points, data['similarity'] * 100];
          return updatedPoints.slice(- (POINTS_NUMBER - 1));
        });
      }

      setCurrentData(prevData => ({...prevData, ...data}));
    };

    subscribeToEvent('current_data', handleReceivedData);

    return () => {
      unsubscribeFromEvent('current_data', handleReceivedData);
    };
  }, [socket, isStarted]);

  /* Duration increment */
  useEffect(() => {
    const interval = setInterval(() => {
      if (selectedPattern && isStarted && !lockedData.current){
        setDuration(duration => duration + 1)
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [selectedPattern, isStarted, lockedData.current]);

  /* Ping to the engine */
  useEffect(() => {
    if (!socket) return;
    const interval = setInterval(() => {
      if (selectedPattern && isStarted && !lockedData.current) {
        sendEvent('selected_pattern', { step: 'ping' });
      }
    }, 500);
    return () => clearInterval(interval);
  }, [selectedPattern, isStarted, lockedData.current, socket]);

  const handleRaiseError = () => {
    console.log('New error raised.');
    postData('live_errors', {date: Math.floor(Date.now() / 1000), pattern: `/api/patterns/${selectedPattern?.id}`});
    showAlert('success', 'The error has been reported.')
  };

  const handleCloseSynthesis = (e) => {
    e.stopPropagation();
    resetInterface();
  };

  const stopProcess = () => {
    lockedData.current = true;
    setShowSynthesis(true);
    sendEvent('selected_pattern', { step: 'stop' });
    setTimeout(resetInterface, 30000);
  };

  return (
    <div className='h-full overflow-hidden bg-gray-100'>
      <div className='flex flex-col items-center justify-center w-full h-full'>
        {
          isStarted && selectedPattern !== null ?
            <div className="w-full h-full flex flex-col justify-around">

              {/* Top part */}
              <div className="w-full h-3/5 flex justify-around p-4 gap-4">
                {/* Left column */}
                <div className="w-7/12 flex flex-col gap-4">
                  {/* Header with selected pattern name */}
                  <div className="bg-perception-blue p-4 text-white text-center relative rounded-md">
                    <div className="absolute top-0 h-full left-0 flex justify-center">
                      <button className="text-white p-4 rounded-sm flex items-center" onClick={() => window.location.reload()}>
                        <ArrowUturnLeftIcon aria-hidden="true" className="size-5 mr-2" /> Back
                      </button>
                    </div>

                    <p className="text-xl">Selected manufacturing process:</p>
                    <p className="text-3xl">
                      {selectedPattern.name}
                    </p>
                  </div>

                  {/* Pattern data */}
                  {
                    currentData?.alert ?
                      <div className="flex justify-center items-center text-yellow-600 text-3xl font-bold h-full rounded-md text-center" style={{backgroundColor: "#fff8a6"}}>
                        {currentData.alert.split('\n').map((line, index) => (
                          <Fragment key={index}>{line}<br/></Fragment>
                        ))}
                      </div>
                    :
                      <div className="flex justify-center items-center text-green-700 text-3xl font-bold h-full rounded-md" style={{backgroundColor: "#BEFFBE"}}>
                        Gestures similar to the model
                      </div>
                  }
                </div>

                {/* Right column */}
                <div className="w-5/12 flex flex-col gap-4">
                  {/* Current step */}
                  <div className="bg-gray-200 p-16 text-center relative rounded-md">
                    <p className="text-xl">Polishing duration:</p>
                    <p className="text-5xl mt-8">
                      {
                        duration > 0 ? formatSecondsToMinutesSeconds(duration) : 'Not started'
                      }
                    </p>

                    {/*<div className="absolute top-4 left-4 flex justify-center">*/}
                    {/*  <button className="bg-perception-blue text-white p-4 rounded-md flex items-center" onClick={handleStart}>*/}
                    {/*    <PlayIcon aria-hidden="true" className="size-5 mr-2" /> Démarrer*/}
                    {/*  </button>*/}
                    {/*</div>*/}

                    <div className="absolute top-4 right-4 flex justify-center">
                      <button className="bg-error text-white p-4 rounded-md flex items-center" onClick={stopProcess}>
                        <StopIcon aria-hidden="true" className="size-5 mr-2" /> STOP
                      </button>
                    </div>
                  </div>

                  {/* Commentary */}
                  <div className="h-full text-center">
                    {
                      selectedPattern.commentary ?
                        <div className="h-full rounded-md bg-blue-50 p-4 flex text-left items-center">
                          <div className="shrink-0">
                            <InformationCircleIcon aria-hidden="true" className="size-8 text-blue-400" />
                          </div>
                          <div className="ml-3 flex-1 md:flex md:justify-between">
                            <h3 className="text-sm text-blue-700">
                              {selectedPattern.commentary.split('\n').map((line, index) => (
                                <Fragment key={index}>{line}<br/></Fragment>
                              ))}
                            </h3>
                          </div>
                        </div>
                      :
                        <div className='h-full flex flex-col justify-center items-center text-center'>
                          <InformationCircleIcon className='w-24 h-24 text-gray-300'/>
                          <div className='text-gray-500'>No information to display</div>
                        </div>
                    }
                  </div>
                </div>
              </div>

              {/* Graph */}
              <div className="h-2/5 bg-white rounded-md m-4 mt-0 p-4 py-8">
                <Graph points={points} />
              </div>

              {/* Logos */}
              <div className="pb-4 flex justify-center items-center text-2xl gap-4">
                <img src={LogoPerception} alt="logo-perception" className="max-w-[200px] max-h-[60px]" />
                x
                <img src={LogoHermes} alt="logo-LCA" className="max-w-[200px] max-h-[60px]" />
              </div>
            </div>
            :
            <>
              <h2 className='my-6 text-3xl font-semibold'>Select a manufacturing process</h2>
              <div className='flex flex-col gap-4 w-80 '>
                {/* TODO [WIP] Manage multi workstation */}
                <InputSelect
                  className='py-3'
                  items={patterns?.filter((pattern) => pattern.workstation.id === 1)}
                  onChange={(pattern) => setSelectedPattern(pattern)}
                  itemToString={(pattern) => pattern?.name}
                  placeholder='Select a manufacturing process'
                  category='secondary'
                  autoComplete='off'
                />
              </div>
              <div className='my-6'>
                <Button type='submit' category='primary-btn' size='small' onClick={handleStart}>
                  Start
                </Button>
              </div>
            </>
        }
      </div>

      {
        showSynthesis && isStarted &&
        <div className="absolute top-0 left-0 w-full h-full p-20 bg-black/50 backdrop-blur-sm">
          <div className="relative bg-white h-full rounded-sm flex flex-col justify-between">
            <div className="absolute top-0 right-0 cursor-pointer" onClick={(e) => handleCloseSynthesis(e)}>
              <XMarkIcon className="w-16 h-16 text-gray-600 m-4"/>
            </div>
            <h1 className="bg-gray-200 text-center text-3xl p-8">Production summary</h1>

            {/* Main space */}
            <div className="h-full flex justify-center items-center overflow-auto gap-4">

              {/* Left column */}
              <div className="w-full h-full flex flex-col justify-center items-center p-4 gap-4">

                {/* Top part */}
                <div className="w-full max-w-[400px] overflow-y-auto h-full">
                  <VideoPlayer videoUrl={videoUrl} />
                </div>

                {/* Bottom part */}
                <div className="w-full h-full overflow-auto p-4">
                  {
                    selectedPattern?.commentary ?
                      <div className="h-full rounded-md bg-blue-50 p-4 flex text-left items-center">
                        <div className="shrink-0">
                          <InformationCircleIcon aria-hidden="true" className="size-8 text-blue-400" />
                        </div>
                        <div className="ml-3 flex-1 md:flex md:justify-between">
                          <h3 className="text-sm text-blue-700">
                            {selectedPattern?.commentary.split('\n').map((line, index) => (
                              <Fragment key={index}>{line}<br/></Fragment>
                            ))}
                          </h3>
                        </div>
                      </div>
                      :
                      <div className='h-full flex flex-col justify-center items-center text-center'>
                        <InformationCircleIcon className='w-24 h-24 text-gray-300'/>
                        <div className='text-gray-500'>No information to display</div>
                      </div>
                  }
                </div>
              </div>

              {/* Right column */}
              <div className="w-full flex flex-col justify-center items-center">
                <div className="flex flex-col justify-around items-center h-full">
                  <h3 className="text-2xl font-bold mb-4">Polishing session data</h3>
                  <table>
                    <tbody>
                      <tr>
                        <td className='px-4'>Contact points frequency</td>
                        {
                          currentData?.contact_state === 0 ?
                            <td className='px-4 text-right text-green-600 font-bold'>Conforms to the model</td>
                            :
                            currentData?.contact_state === -1 ?
                              <td className='px-4 text-right text-yellow-500 font-bold'>To increase</td>
                              :
                                <td className='px-4 text-right text-yellow-500 font-bold'>To reduce</td>
                        }
                      </tr>
                      <tr>
                        <td className='px-4'>Contacts average duration</td>
                        {
                          currentData?.duration_state === 0 ?
                            <td className='px-4 text-right text-green-600 font-bold'>Conforms to the model</td>
                            :
                            currentData?.duration_state === -1 ?
                              <td className='px-4 text-right text-yellow-500 font-bold'>To increase</td>
                              :
                              <td className='px-4 text-right text-yellow-500 font-bold'>To reduce</td>
                        }
                      </tr>
                      <tr>
                        <td className='px-4'>Gestures</td>
                        {
                          currentData?.hands_state === 0 ?
                            <td className='px-4 text-right text-green-600 font-bold'>Conforms to the model</td>
                            :
                            <td className='px-4 text-right text-yellow-500 font-bold'>To improve</td>
                        }
                      </tr>
                      <tr>
                        <td className='px-4'>Polishing duration</td>
                        <td className='px-4 text-right'>{formatSecondsToMinutesSeconds(duration)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="w-full flex justify-around mt-12">
                  <button className="text-black p-4 rounded-md flex items-center bg-gray-200" onClick={() => window.location.reload()}>
                    <ArrowUturnLeftIcon aria-hidden="true" className="size-5 mr-2" /> Back
                  </button>
                  <button className="bg-perception-blue text-white p-4 rounded-md flex items-center" onClick={resetInterface}>
                    <PlayIcon aria-hidden="true" className="size-5 mr-2" /> New polishing session
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-center bg-gray-200 p-4">
              <button className="bg-error text-white p-4 rounded-sm" onClick={handleRaiseError}>Report an error</button>
            </div>
          </div>
        </div>
      }
    </div>
  );
};
