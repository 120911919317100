import { Modal } from '../../../../components/modals/Modal';
import { useEffect, useState } from "react";

export const EditPatternModal = ({ isOpen, setIsEditPatternModalOpen, patternToEdit, onEdit }) => {

  const [value, setValue] = useState(patternToEdit?.name);

  useEffect(() => {
    setValue(patternToEdit?.name);
  }, [patternToEdit]);

  const handleValueChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsEditPatternModalOpen}>
      <div className='p-4'>
        <h3 className='text-lg text-start font-medium leading-6 text-gray-900'>Renommer la gamme</h3>
        <div className='my-4'>
          <input type="text" className="bg-gray-200 p-2 rounded-sm w-full" value={value} onChange={handleValueChange} />
        </div>
        <div className='flex justify-start'>
          <button
            className='inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50'
            onClick={() => setIsEditPatternModalOpen(false)}
          >
            Annuler
          </button>
          <button
            className='ml-4 inline-flex justify-center rounded-md border border-transparent bg-perception-blue px-4 py-2 text-sm font-medium text-white shadow-sm'
            onClick={() => {
              onEdit(value);
              setIsEditPatternModalOpen(false);
            }}
          >
            Renommer
          </button>
        </div>
      </div>
    </Modal>
  );
};
