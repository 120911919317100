import { Panel } from '../../../../components/panels/Panel';
import { FrameViewer } from './components/FrameViewer';
import { PatternEventTypesPanel } from './panels/PatternEventTypesPanel';
import { PatternDataPanel } from './panels/PatternDataPanel';
import { PatternPhasesPanel } from "./panels/PatternPhasesPanel";
import {
  isPatternAnnotationMode, PATTERNS_ANNOTATION_MODE_COMMENTS_ONLY,
  PATTERNS_ANNOTATION_MODE_PHASES, PATTERNS_ANNOTATION_MODE_STEPS
} from "../../../../services/live-interface/patterns-annotation-service";

export const Annotation = ({ pattern }) => {

  return (
    <>
      {/* LEFT PANEL */}
      <div className='flex flex-col w-1/6 h-full'>
        {
          !isPatternAnnotationMode(PATTERNS_ANNOTATION_MODE_COMMENTS_ONLY) &&
            <div className='flex flex-col h-1/2'>

              {/*Using event types*/}
              {isPatternAnnotationMode(PATTERNS_ANNOTATION_MODE_STEPS) ?
                <Panel title='ETAPES DE LA GAMME'>
                  <div className='flex flex-col gap-1'>
                    <PatternEventTypesPanel
                      patternEventTypes={pattern.pattern_event_types}
                    />
                  </div>
                </Panel>
                : isPatternAnnotationMode(PATTERNS_ANNOTATION_MODE_PHASES) ?
                <Panel title='PHASES DE LA GAMME'>
                  <div className='flex flex-col gap-1'>
                    <PatternPhasesPanel
                      patternPhases={pattern.pattern_phases}
                    />
                  </div>
                </Panel>
                : null
              }
            </div>
        }
        <div className={`flex flex-col ${isPatternAnnotationMode(PATTERNS_ANNOTATION_MODE_COMMENTS_ONLY) ? 'h-full' : 'h-1/2' }`}>
          <Panel title='DONNEES DE LA GAMME'>
            <div className='flex flex-col h-full'>
              <PatternDataPanel
                patternData={pattern.pattern_data}
              />
            </div>
          </Panel>
        </div>
      </div>

      {/* MID PANEL */}
      <div className='flex flex-col w-5/6 h-full overflow-hidden'>
        <Panel category='main' title='ANNOTATIONS' noPadding={true}>
          <FrameViewer pattern={pattern} />
        </Panel>
      </div>
    </>
  );
};
